import React, { useState, useEffect } from "react";
import "./App.css";
import { v4 as uuidv4 } from "uuid";

const headerStandard = "Unser Event-Foto-Uploader";
const uploadIdentifier = "uploadIdentifier";

const ImageUpload = () => {
  // const [selectedFile, setSelectedFile] = useState(null);
  const [identifier, setIdentifier] = useState("");
  const [headerText, setHeaderText] = useState(headerStandard);

  useEffect(() => {
    // Load the identifier from localStorage if it exists
    const savedIdentifier = localStorage.getItem(uploadIdentifier);
    if (savedIdentifier) {
      setIdentifier(savedIdentifier);
    }
  }, []);

  const handleIdentifierChange = (event) => {
    const value = event.target.value.trim();
    setIdentifier(value);

    // Save the identifier to localStorage
    localStorage.setItem(uploadIdentifier, value);
  };

  const handleMultipleFileChange = async (event) => {
    const files = event.target.files;
    if (files.length === 0) {
      setHeaderText("Keine Dateien ausgewählt.");
      return;
    }

    setHeaderText("Hochladen...");

    for (let i = 0; i < files.length; i++) {
      const fileExtension = files[i].name.split(".").pop();
      const uniqueFilename = `${identifier}-${uuidv4()}.${fileExtension}`;

      const modifiedFile = new File([files[i]], uniqueFilename, {
        type: files[i].type,
      });

      const formData = new FormData();
      formData.append("file", modifiedFile);
      try {
        const response = await fetch("upload.php", {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          setHeaderText(`Upload erfolgreich für Datei ${i + 1}`);
        } else {
          setHeaderText(`Upload fehlgeschlagen für Datei ${i + 1}`);
        }
      } catch (error) {
        setHeaderText(`Fehler beim Upload von Datei ${i + 1}`);
      }
    }

    setTimeout(() => setHeaderText(headerStandard), 5000);
  };

  return (
    <div className="page-container">
      <div className="header-container">
        <header className="page-header">
          <h1>{headerText}</h1>
        </header>
      </div>
      <div className="upload-container">
        <form className="upload-form">
          <input
            type="text"
            value={identifier}
            onChange={handleIdentifierChange}
            placeholder="optional - trage ein Kürzel für dich ein"
            className="identifier-input"
          />
          <input
            type="file"
            accept="image/*"
            id="multiple-file-upload"
            multiple
            onChange={handleMultipleFileChange}
            className="upload-input"
            style={{ display: "none" }} // Hide the file input itself
          />
          <label
            htmlFor="multiple-file-upload"
            className="custom-upload-button"
          >
            mach ein Foto / wähle aus der Galerie
          </label>
          <input
            type="file"
            accept="image/*"
            capture="environment"
            id="camera-upload"
            onChange={handleMultipleFileChange}
            className="upload-input"
          />
          <label htmlFor="camera-upload" className="camera-icon-button">
            <i className="fas fa-camera"></i>
          </label>
        </form>
      </div>
    </div>
  );
};

export default ImageUpload;
